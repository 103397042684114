import PropTypes from 'prop-types';
import './AssuranceCard.scss';
import IconBoxDiamondWhite from '../assets/icons/IconBoxDiamondWhite';

const BlogCard = ({ title, children, imageSrc }) => {
  return (
    <div className="assurance-card blog">
      <img src={imageSrc} className="assurance-card__image" alt="Assurance Card Image" />
      <div className="assurance-card__content">
        <h3>
          <IconBoxDiamondWhite />
          <span>{title}</span>
        </h3>
      </div>
      <a href="/" className="assurance-card__button">
        <img src={require('../assets/ButtonIconWhite.svg').default} alt="Button Icon" />
        <p>Savoir plus</p>
      </a>
    </div>
  );
};

BlogCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  imageSrc: PropTypes.string.isRequired, // Add imageSrc prop for validation
};

export default BlogCard;
