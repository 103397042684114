import LandingPage from './templates/LandingPage';
import SectionHero from './sections/SectionHero';
import SectionAdvantages from './sections/SectionAdvantages';
import SectionContactSteps from './sections/SectionContactSteps'; // Ajoutez la section de formulaire.
import SectionOurValues from './sections/SectionOurValues';

function Souscription() {
    return (
        <LandingPage>
            <SectionContactSteps />
        </LandingPage>
    );
}

export default Souscription;
