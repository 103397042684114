import React from 'react';
import { Phone, Mail, Clock } from 'lucide-react';
import '../styles/banner.css'; // Importer le fichier CSS

const MarketingBanner = () => {
    return (
        <div className="marketing-banner-container">
            {/* Version pour Desktop et Tablette */}
            <div className="marketing-banner-wrapper desktop-tablet">
                <div className="marketing-banner-details">
                    {/* Email Contact */}
                    <div className="marketing-banner-detail-item">
                        <Mail className="marketing-banner-icon" />
                        <span>Besoin d'assurances ? Contactez-nous par email à </span>
                        <a href="mailto:assurance@fast-protect.fr " className="marketing-banner-email">assurance@fast-protect.fr</a>
                    </div>

                    {/* Opening Hours on a Second Line */}
                    <div className="marketing-banner-detail-item second-line">
                        <Clock className="marketing-banner-icon" />
                        <span>Nous sommes ouverts du lundi au vendredi, de 9h à 18h.</span>
                    </div>
                </div>

                {/* Call to Action Button Aligned to Right */}
                <div className="call-to-action-container">
                    <a 
                        href="tel:0186617242" 
                        className="call-to-action-link"
                        aria-label="Appelez-nous directement"
                        onMouseEnter={e => {
                            e.currentTarget.style.backgroundColor = '#99ffde';
                            e.currentTarget.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
                        }}
                        onMouseLeave={e => {
                            e.currentTarget.style.backgroundColor = '#99ffde';
                            e.currentTarget.style.boxShadow = '0 1px 2px rgba(0,0,0,0.1)';
                        }}
                    >
                        <Phone className="marketing-banner-icon" />
                        <span>Appelez-nous directement : 01 86 61 72 42</span>
                    </a>
                </div>
            </div>

            {/* Version pour Mobile */}
            <div className="marketing-banner-wrapper mobile">
                <div className="marketing-banner-mobile-details">
                    <span>Besoin d'assurances ?</span>
                    {/* Button to Call Directly */}
                    <a href="tel:0186617242" className="marketing-banner-button mobile-button">
                        <Phone className="marketing-banner-icon" />
                        Appelez-nous directement
                    </a>

                    {/* Adding Opening Hours for Mobile Version */}
                    <div className="marketing-banner-detail-item mobile-opening-hours">
                        <Clock className="marketing-banner-icon" />
                        <span>De 9h à 19h.</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MarketingBanner;
