import React from 'react';
import CarouselCard from '../components/CarouselCard';
import Grid from '../components/Grid';
import GridCell from '../components/GridCell';
import HeroCard from '../components/HeroCard';
import MaxWidth from '../components/MaxWidth';
import IconSimpleSend from '../assets/icons/IconSimpleSend'; // Assurez-vous que cet import est correct
import heroAssurance1 from '../assets/images/hero-assurances-1.png';
import heroAssurance2 from '../assets/images/hero-assurances-2.png';
import heroImage1 from '../assets/images/ceo-usine.png'; // Correction des imports


function SectionHeroAssurances() {
  return (
    <div style={{ padding: "var(--fp-size-section-padding-v) 0", backgroundColor: "var(--fp-color-blue-degree-95)" }}>
      <MaxWidth>
        <Grid gutter={16} maxColumns={2}>
          <GridCell>
            <div style={{ height: "368px" }}>
              <HeroCard
                title={
                  <>
                    <span className='fp-text-style-header-big'>Nos&nbsp;</span>
                    <span className='fp-text-style-header-big-special'>Assurances</span>
                    <br />
                    <span className='fp-text-style-header-big'>Pour tous vos besoins</span>
                  </>
                }
                description={
                  "Découvrez notre gamme d'assurances adaptées à vos besoins professionnels. Que ce soit pour une assurance cyber-risques ou responsabilité civile, Fast Protect vous couvre avec des solutions rapides et fiables."
                }
                primaryAction={{
                  icon: <IconSimpleSend fill={"var(--fp-comp-hero-card-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />,
                  action: "Prendre un RDV",
                  onClick: () => { window.location.href = "https://fast-protect.fr/rdv"; }
                }}
                secondaryAction={{
                  icon: <IconSimpleSend fill={"var(--fp-comp-hero-card-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />,
                  action: "Être contacté par un expert",
                  onClick: () => { console.log("secondary action"); }
                }}
              />
            </div>
          </GridCell>
          <GridCell>
            <div style={{ height: "368px" }}>
              <CarouselCard
                items={[
                  {
                    title: "Assurance Cyber-Risques",
                    images: [
                      heroAssurance1,
                      heroImage1,
                      heroAssurance1,
                    ]
                  },
                  {
                    title: "Assurance Responsabilité Civile",
                    images: [
                      "https://images.unsplash.com/photo-1531123897727-8f129e1688ce",
                      "https://images.unsplash.com/photo-1531123897727-8f129e1688ce",
                      "https://images.unsplash.com/photo-1531123897727-8f129e1688ce",
                    ]
                  },
                  {
                    title: "Matériel Professionnel",
                    images: [
                      "https://images.unsplash.com/photo-1504834636679-cd3acd047c06",
                      "https://images.unsplash.com/photo-1504834636679-cd3acd047c06",
                      "https://images.unsplash.com/photo-1504834636679-cd3acd047c06",
                    ]
                  },
                ]}
              />
            </div>
          </GridCell>
        </Grid>
      </MaxWidth>
    </div>
  );
}

export default SectionHeroAssurances;
