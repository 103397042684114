import React, { useEffect } from 'react';  // Utilisation de useEffect pour intégrer le script HubSpot
import MaxWidth from '../components/MaxWidth';
import SectionLabel from '../components/SectionLabel';
import IconBoxQ14 from '../assets/icons/IconBoxQ14';
import Spacer from '../components/Spacer';
function SectionRDV() {
  // Utilisation de useEffect pour injecter le script HubSpot
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.async = true;
    document.body.appendChild(script);

    // Nettoyage du script lorsque le composant est démonté
    return () => {
      document.body.removeChild(script);
    };
  }, []);
    return (
    <div style={{ padding: "var(--fp-size-section-padding-v2) 0", backgroundColor: "var(--fp-color-white)", position: "relative" }}>
      <MaxWidth>
        <div style={{position: "relative", zIndex: "1"}}>
          <SectionLabel
            icon={<IconBoxQ14 fill={"var(--fp-comp-section-label-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
            title={"Prendre un rendez-vous"}
            isWhite={true}
          />
          <Spacer size={"16px"} />
          <h2 className='fp-text-style-header-large'>
            Nos experts à votre disposition
            <br />
            Prenez rendez-vous en toute simplicité !
          </h2>
          <Spacer size={"48px"} />

          {/* Calendrier HubSpot */}
          <div className="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/kettani?embed=true"></div>
        </div>
      </MaxWidth>
    </div>
  );
}
export default SectionRDV;