import '../components/AssurancesSection.scss';
import MaxWidth from '../components/MaxWidth';
import SectionLabel from '../components/SectionLabel';
import Spacer from '../components/Spacer';
import IconBoxDiamondGreen from '../assets/icons/IconBoxDiamondGreen';
import FloatingLogo from '../components/FloatingLogo';
import AssuranceCardCoinImage from '../assets/AssuranceCardCoinImage.png';
import AssuranceCardPaperImage from '../assets/AssuranceCardPaperImage.png';
import AssuranceCardMoneyImage from '../assets/AssuranceCardMoneyImage.png';
import BlogCard from '../components/BlogCard';

const blogData = [
    {
        imageSrc: AssuranceCardCoinImage,
        title: "Les 5 Risques Majeurs auxquels les Micro-Entrepreneurs sont Confrontés et Comment les Éviter",
    },
    {
        imageSrc: AssuranceCardPaperImage,
        title: "Pourquoi les Auto-Entrepreneurs Ont Besoin d'une Assurance Personnalisée",
    },
    {
        imageSrc: AssuranceCardMoneyImage,
        title: "Guide Complet pour Choisir la Bonne Assurance pour Votre Micro-Entreprise",
    },
    {
        imageSrc: AssuranceCardCoinImage,
        title: "L'Importance de l'Audit des Contrats pour Optimiser le \"Time to Market\"",
    },
    {
        imageSrc: AssuranceCardPaperImage,
        title: "Comment Fast Protect Peut Aider les Micro-Entrepreneurs à Gagner en Crédibilité",
    },
    {
        imageSrc: AssuranceCardMoneyImage,
        title: "Les Avantages d'une Assurance sans Engagement pour les Auto-Entrepreneurs",
    },
    {
        imageSrc: AssuranceCardCoinImage,
        title: "Étude de Cas : Comment [Nom d'une PME] a Protégé son Activité avec Fast Protect",
    },
    {
        imageSrc: AssuranceCardPaperImage,
        title: "Les Meilleures Pratiques pour Protéger Votre Entreprise contre les Imprévus..",
    },
    {
        imageSrc: AssuranceCardMoneyImage,
        title: "Comment l'Assurance Peut Accélérer la Croissance de Votre Micro-Entreprise",
    },
];

const SectionBlog = () => {
    return (
        <div style={{ padding: "var(--fp-size-section-padding-v2) 0", position: "relative" }}>
            <MaxWidth>
                <div style={{ position: "relative", zIndex: "1" }}>
                    <SectionLabel
                        icon={<IconBoxDiamondGreen />}
                        title={"Nos assurances"}
                        isWhite={true}
                    />
                    <Spacer size={"16px"} />
                    <h2 className='fp-text-style-header-large'>
                        Nous offrons le meillieur service pour<br />satisfaire votre besoin rapidement
                    </h2>
                    <Spacer size={"48px"} />

                    <div className="assurances-cards">
                        <div className="assurances-cards__grid">
                            {blogData.map((assurance, index) => (
                                <BlogCard key={index} imageSrc={assurance.imageSrc} title={assurance.title}>
                                    {assurance.description}
                                </BlogCard>
                            ))}
                        </div>
                    </div>
                </div>
            </MaxWidth>

            <FloatingLogo isWhite={false} />
        </div>
    );
};

export default SectionBlog;
