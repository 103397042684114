import React from 'react';
import IconSimpleSend from '../assets/icons/IconSimpleSend';
import CarouselCard from '../components/CarouselCard';
import Grid from '../components/Grid';
import GridCell from '../components/GridCell';
import HeroCard from '../components/HeroCard';
import MaxWidth from '../components/MaxWidth';
import heroImage1 from '../assets/images/fast-protect-flot-vehicule.png';
import heroImage2 from '../assets/images/hero-image-1.png'; //

function SectionHero() {
  return (
    <div style={{ padding: "var(--fp-size-section-padding-v) 0", backgroundColor: "var(--fp-color-blue-degree-95)" }}>
      <MaxWidth>
        <Grid gutter={16} maxColumns={2}>
          <GridCell>
            <div style={{ height: "368px" }}>
              <HeroCard
                title={
                  <>
                    <span className='fp-text-style-header-big'>Protection&nbsp;</span>
                    <span className='fp-text-style-header-big-special'>Rapide</span>
                    <br />
                    <span className='fp-text-style-header-big'>Avec Fast Protect</span>
                  </>
                }
                description={
                  "Bienvenue chez Fast Protect, où la protection juridique est simple et intuitive. Dans le monde rapide d'aujourd'hui, nous savons que l'efficacité est essentielle. C'est pourquoi notre plateforme en ligne vous permet de souscrire facilement.                  "
                }
                primaryAction={{
                  icon: <IconSimpleSend fill={"var(--fp-comp-hero-card-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />,
                  action: "Prendre un RDV",
                  onClick: () => { window.location.href = "https://fast-protect.fr/rdv"; }
                }}                
                secondaryAction={{
                  icon: <IconSimpleSend fill={"var(--fp-comp-hero-card-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />,
                  action: "Être contacté par un expert",
                  onClick: () => { console.log("secondary action") }
                }}
              />
            </div>
          </GridCell>
          <GridCell>
            <div style={{ height: "368px" }}>
              <CarouselCard
                items={[
                  {
                    title: "Assurance Protection juridique ",
                    images: [
                      heroImage2,
                      heroImage1, 
                      heroImage1,
                    ]
                  },                 
                ]}
              />
            </div>
          </GridCell>
        </Grid>
      </MaxWidth>
    </div>
  );
}

export default SectionHero;