import React from 'react'

function LogoIcon({ fill }) {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 24L5.04358 3.82566C5.22193 3.11228 5.77894 2.55526 6.49233 2.37692L16 0L15.4009 2.39656C15.2225 3.10994 14.6655 3.66696 13.9521 3.8453L8.5795 5.18846C8.22281 5.27763 7.9443 5.55614 7.85512 5.91283L6.22222 12.4444L13.3333 10.6667L12.7342 13.0632C12.5558 13.7766 11.9988 14.3336 11.2854 14.512L5.91283 15.8551C5.55614 15.9443 5.27763 16.2228 5.18846 16.5795L3.8453 21.9521C3.66696 22.6655 3.10994 23.2225 2.39656 23.4009L0 24Z" fill={fill} />
      <path d="M16.2701 3.2658C15.5567 3.44415 14.9997 4.00116 14.8214 4.71455L13.3333 10.6667L15.7299 10.0675C16.4433 9.88918 17.0003 9.33217 17.1786 8.61878L17.6329 6.80172C17.7221 6.44503 18.0006 6.16652 18.3573 6.07735L21.3333 5.33334L19.7004 11.865C19.6113 12.2216 19.3328 12.5001 18.9761 12.5893L13.6035 13.9325C12.8901 14.1108 12.3331 14.6678 12.1547 15.3812L10.6667 21.3333L13.0632 20.7342C13.7766 20.5558 14.3336 19.9988 14.512 19.2854L14.9662 17.4684C15.0554 17.1117 15.3339 16.8332 15.6906 16.744L21.0632 15.4009C21.7766 15.2225 22.3336 14.6655 22.512 13.9521L25.7778 0.888885L16.2701 3.2658Z" fill={fill} />
    </svg>
  )
}

export default LogoIcon