
import React from 'react'
import LandingPage from './templates/LandingPage'
import Spacer from './components/Spacer'
import SectionMentionsLegales from './sections/SectionMentionsLegales'

function MentionsLegales() {
  return (
    <LandingPage>
      <Spacer size={"48px"}/>
      <SectionMentionsLegales/>
    </LandingPage>
  )
}

export default MentionsLegales



