function Spacer({ size }) {
  return (
    <div style={{
      height: size,
      minhHeight: size,
      width: size,
      minWidth: size,
    }}></div>
  )
}

export default Spacer