import './App.scss';
import SectionAdvantagesSubscription from './sections/SectionAdvantagesSubscription';
import SectionContact from './sections/SectionContact';
import SectionFAQ from './sections/SectionFAQ';
import SectionHero from './sections/SectionHero';
import SectionRdv from './sections/SectionRdv';  // Utilisation de l'import correct pour la section RDV
import SectionRegistration from './sections/SectionRegistration';
import SectionReviews from './sections/SectionReviews';
import LandingPage from './templates/LandingPage';
import { Toaster } from 'react-hot-toast';
import SectionAdvantages from './sections/SectionAdvantages';



function App() {
  return (
    <div className="App">
    
      <LandingPage>
        <SectionHero />
        <SectionAdvantages />  {/* Utilisation correcte de la section RDV */}
        <SectionAdvantagesSubscription />
        <SectionRegistration />
        <SectionFAQ />  {/* Utilisation correcte de la FAQ */}
        <SectionReviews />
        <SectionContact />
      </LandingPage>
      <Toaster />
    </div>
  );
}

export default App;
