function IconSimpleSend({ fill, stroke }) {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.7277 1.63599L9.48509 14.3639L8.07088 12.9497C6.50878 11.3876 6.50878 8.85494 8.07088 7.29284L13.7277 1.63599Z" fill={fill} />
      <path d="M13.7277 1.63599L1 5.87913L2.41421 7.29335C3.97631 8.85544 6.50878 8.85494 8.07088 7.29284L13.7277 1.63599Z" fill={fill} />
      <path d="M13.7277 1.63599L9.48509 14.3639L8.07088 12.9497C6.50878 11.3876 6.50878 8.85494 8.07088 7.29284M13.7277 1.63599L8.07088 7.29284M13.7277 1.63599L1 5.87913L2.41421 7.29335C3.97631 8.85544 6.50878 8.85494 8.07088 7.29284" stroke={stroke} />
    </svg>
  )
}

export default IconSimpleSend