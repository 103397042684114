import React, { useEffect } from 'react';
import LandingPage from './templates/LandingPage';
import SectionHero from './sections/SectionHero';
import SectionAdvantagesSubscription from './sections/SectionAdvantagesSubscription';
import SectionContactSteps from './sections/SectionContactSteps';

function Contact() {
    // Charger le script HubSpot lorsqu'on arrive sur cette page
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js-eu1.hs-scripts.com/144634511.js'; // Remplace XXXXX par ton ID HubSpot
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        // Nettoyer le script quand le composant est démonté
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <LandingPage>
            <SectionHero />
            <SectionAdvantagesSubscription />
            <SectionContactSteps />
        </LandingPage>
    );
}

export default Contact;
