import React from 'react';
import { Link } from 'react-router-dom';
import LogoBrand from '../assets/LogoMenu.svg';
import FooterEmail from '../assets/FooterEmail.svg';
import Instagram from '../assets/Instagram.svg';
import Facebook from '../assets/Facebook.svg';
import LinkedIn from '../assets/Linkedin.svg'; 
import PhoneIcon from '../assets/PhoneIcon.svg'; 
import AddressIcon from '../assets/AddressIcon.svg';
import MaxWidth from '../components/MaxWidth';
import styles from '../Footer.scss';

const Footer = () => {
    return (
        <div className="footer">
            <MaxWidth>
                <div className="logo-container">
                    <img src={LogoBrand} alt="Logo" />
                    <p>Fast Protect</p>
                </div>
                <div className="nav-container">
                    <ul>
                        <li><Link to="/">Accueil</Link></li>
                        <li><Link to="/assurances">Nos Assurances</Link></li>
                        <li><Link to="/propos">À Propos</Link></li>
                        <li><Link to="/rdv">Prendre un RDV</Link></li>
                    </ul>

                    <ul className="center-links">
                        <li><Link to="/mentions-legales">Mentions Légales</Link></li>
                        <li><Link to="/politique-confidentialite">Politique de Confidentialité</Link></li>
                    </ul>

                    <div className="contact">
                        <p>Contactez-nous</p>

                        <a href="mailto:Assurances@fast-protect.fr">
                            <img src={FooterEmail} width={20} alt="Email" />
                            <span>assurance@fast-protect.fr </span>
                        </a>

                        <div className="phone">
                            <a href="tel:0186617241">
                                <img src={PhoneIcon} width={20} alt="Téléphone" />
                                <span>0186617241</span>
                            </a>
                            <br /> {/* Ajout d'un saut de ligne */}
                            <a href="tel:0186617242">
                                <img src={PhoneIcon} width={20} alt="Téléphone" />
                                <span>0186617242</span>
                            </a>
                        </div>
                        <div className="address">
                            <img src={AddressIcon} width={20} alt="Adresse" />
                            <span>10 rue Penthièvre, 75008 Paris, France</span>
                        </div>
                        <div className="social-media" style={{ display: 'flex', gap: '10px', justifyContent: 'center', marginTop: '10px' }}>
                            <a href="https://www.instagram.com/fast_protect" target="_blank" rel="noopener noreferrer">
                                <img src={Instagram} width={20} alt="Instagram" />
                            </a>
                            <a href="https://www.facebook.com/fast_protect" target="_blank" rel="noopener noreferrer">
                                <img src={Facebook} width={20} alt="Facebook" />
                            </a>
                            <a href="https://www.linkedin.com/company/fast_protect" target="_blank" rel="noopener noreferrer">
                                <img src={LinkedIn} width={20} alt="LinkedIn" />
                            </a>
                        </div>
                        
                    </div>
                </div>
            </MaxWidth>
        </div>
    );
};

export default Footer;
