import MaxWidth from "../components/MaxWidth";
import SectionLabel from "../components/SectionLabel";
import Spacer from "../components/Spacer";
import IconSimpleSend from "../assets/icons/IconSimpleSend";
import InputText from "../components/InputText";
import { useState } from "react";
import IconSimplePerson from "../assets/icons/IconSimplePerson";
import IconSimpleAt from "../assets/icons/IconSimpleAt";
import IconSimpleSmartPhone from "../assets/icons/IconSimpleSmartPhone";
import ButtonAction from "../components/ButtonAction";
import FloatingLogo from "../components/FloatingLogo";
import "../styles/SectionContact.css";
import axios from "axios";
import toast from "react-hot-toast";

function SectionContact() {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phone: "",
    sector: "",  // Secteur d'activité
    siret: ""    // Numéro SIRET
  });

  const [loading, setLoading] = useState(false);

  const onValueChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { firstName, lastName, company, email, phone, sector, siret } = formValues;

    // Validation des champs obligatoires
    if (!firstName || !lastName || !email || !phone) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    setLoading(true);
    try {
      const hubspotResponse = await submitHubspotForm(firstName, lastName, company, email, phone, sector, siret);
      toast.success(hubspotResponse.data.inlineMessage || "Formulaire soumis avec succès !");
      resetForm();
    } catch (err) {
      toast.error("Erreur lors de la soumission du formulaire.");
    } finally {
      setLoading(false);
    }
  };

  const submitHubspotForm = async (firstName, lastName, company, email, phone, sector, siret) => {
    const portalId = '144634511'; // ID du portail HubSpot
    const formGuid = 'fe9c1e7b-195f-4c17-b925-b61f383c9196'; // GUID du formulaire

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        fields: [
          { name: 'firstname', value: firstName },
          { name: 'lastname', value: lastName },
          { name: 'company', value: company },
          { name: 'email', value: email },
          { name: 'phone', value: phone },
          { name: 'sector', value: sector },
          { name: 'siret', value: siret }
        ],
      },
      config
    );

    return response;
  };

  const resetForm = () => {
    setFormValues({
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      phone: "",
      sector: "",
      siret: ""
    });
  };

  return (
    <div id="contactForm" className="sectionContact" style={{ padding: "var(--fp-size-section-padding-v) 0", backgroundColor: "var(--fp-color-white)", position: "relative" }}>
      <MaxWidth>
        <div style={{ position: "relative", zIndex: 1 }}>
          <SectionLabel
            icon={<IconSimpleSend fill={"var(--fp-comp-section-label-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
            title="Contact"
            isWhite={true}
          />
          <Spacer size="16px" />
          <h2 className='fp-text-style-header-large'>
            Nos experts à votre disposition
            <br />
            Contactez-nous en un clic !
          </h2>
          <Spacer size="48px" />

          <form onSubmit={handleSubmit} className="contactForm">
            <InputText
              placeholder="Prénom"
              name="firstName"
              value={formValues.firstName}
              onChange={onValueChange}
              icon={<IconSimplePerson fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isWhite={true}
            />
            <InputText
              placeholder="Nom"
              name="lastName"
              value={formValues.lastName}
              onChange={onValueChange}
              icon={<IconSimplePerson fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isWhite={true}
            />
            <InputText
              placeholder="Nom de l'entreprise (Facultatif)"
              name="company"
              value={formValues.company}
              onChange={onValueChange}
              icon={<IconSimpleAt fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isWhite={true}
            />
            <InputText
              placeholder="E-mail"
              name="email"
              value={formValues.email}
              onChange={onValueChange}
              icon={<IconSimpleAt fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isWhite={true}
            />
            <InputText
              placeholder="Numéro de téléphone"
              name="phone"
              value={formValues.phone}
              onChange={onValueChange}
              icon={<IconSimpleSmartPhone fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isWhite={true}
            />
            <InputText
              placeholder="Secteur d'activité"
              name="sector"
              value={formValues.sector}
              onChange={onValueChange}
              icon={<IconSimpleAt fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isWhite={true}
            />
            <InputText
              placeholder="Numéro SIRET"
              name="siret"
              value={formValues.siret}
              onChange={onValueChange}
              icon={<IconSimpleAt fill={"var(--fp-comp-input-text-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isWhite={true}
            />
            <ButtonAction
              title={loading ? "Envoi en cours..." : "Envoyer"}
              icon={<IconSimpleSend fill={"var(--fp-comp-button-action-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
              isSpecial={true}
              isFullWidth={true}
            />
          </form>
        </div>
      </MaxWidth>
      <FloatingLogo isWhite={false} />
    </div>
  );
}

export default SectionContact;
