import React from 'react';
import '../styles/SectionMentionsLegales.css';

function SectionMentionsLegales() {
  return (
    <div className="legal-page">
      <h1 className="legal-title">Mentions Légales</h1>
      <p className="legal-info">
        FAST PROTECT SARL au capital de 1000 € - Siège social : 10 rue Penthièvre 75008 Paris France.
        RCS 901 663 500, Immatriculée à l’ORIAS sous le N°22001502 (www.orias.fr).
      </p>
      <p className="legal-info">
        Conformément aux obligations légales inhérentes à notre profession réglementée d’intermédiaire
        en assurances, voici ci-après les informations relatives à notre société et nos mentions légales
        (art. L.521-2 du Code des Assurances).
      </p>
      <p className="legal-info">
        FAST PROTECT a souscrit une assurance Responsabilité Civile aux articles L 512-6 et L 512-7 du
        Code des assurances. Nous nous engageons à une utilisation responsable des données personnelles,
        conformément à la loi n° 78-17 du 6 janvier 1978 et au RGPD n°2016-679 du 27 avril 2016.
      </p>

      <h2 className="legal-subtitle">Identité et immatriculation</h2>
      <p className="legal-info">
        FAST PROTECT<br />
        10 rue de Penthièvre 75008 PARIS - FRANCE<br />
        SARL de courtage d’assurance au capital de 1000 € - 901 663 500 RCS Paris - APE 6629Z<br />
        N° TVA Intracommunautaire : FR44883713596 - Inscrit à l’ORIAS N° 22001502 (<a href="https://www.orias.fr">www.orias.fr</a>) - 
        sous le contrôle de l’ACPR (Autorité de Contrôle Prudentiel et de Résolution), 4 Place de 
        Budapest, CS 92459, 75436 Paris Cedex 9 (<a href="https://acpr.banque-france.fr">www.acpr.banque-france.fr</a>).
      </p>

      <h2 className="legal-subtitle">Protection des données personnelles</h2>
      <p className="legal-info">
        FAST PROTECT s’engage à une utilisation responsable des données personnelles qu’elle traite,
        conformément aux règles en vigueur (Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, 
        aux fichiers et aux libertés modifiée et Règlement Général sur la Protection des Données n°2016-679
        du 27 avril 2016).
      </p>
      <p className="legal-info">
        Les données recueillies sont utilisées pour la gestion et le suivi de vos dossiers, ainsi que 
        pour l’envoi de documents concernant les produits d’assurance, destinataire avec leurs mandataires, 
        partenaires, assureurs et réassureurs.
      </p>
      
      <h2 className="legal-subtitle">Vos droits</h2>
      <p className="legal-info">
        Vous disposez d’un droit d’accès et de rectification des données vous concernant, ainsi que du droit 
        de vous opposer à leur traitement pour un motif légitime. Veuillez adresser vos demandes à :<br />
        FAST PROTECT, 10 rue de Penthièvre 75008 Paris ou par mail à <a href="mailto:contact@fast-protect.fr">contact@fast-protect.fr</a>.
      </p>
      <p className="legal-info">
        Vous disposez également du droit d’introduire une réclamation auprès de la CNIL (<a href="https://www.cnil.fr">www.cnil.fr</a>).
        En cas de désaccord, vous pouvez également vous inscrire sur la liste d’opposition au démarchage téléphonique 
        « Bloctel » à l’adresse suivante : <a href="https://www.bloctel.gouv.fr">www.bloctel.gouv.fr</a>.
      </p>

      <h2 className="legal-subtitle">Réclamations</h2>
      <p className="legal-info">
        En cas de réclamation concernant votre contrat, vous pouvez contacter le Service Réclamations de FAST PROTECT :<br />
        Service Réclamations FAST PROTECT, 10 rue de Penthièvre 75008 Paris France.
      </p>
      <p className="legal-info">
        En dernier recours, vous pouvez saisir la Médiation de l’Assurance via :<br />
        Adresse postale : La Médiation de l’Assurance, Pole CSCA, TSA 50110 75441 Paris Cedex 09<br />
        Email : <a href="mailto:le.mediateur@mediation-assurance.org">le.mediateur@mediation-assurance.org</a><br />
        Site web : <a href="https://www.mediation-assurance.org">www.mediation-assurance.org</a>.
      </p>
    </div>
  );
}

export default SectionMentionsLegales;
