import React from 'react';
import '../styles/SectionPolitiqueConfidentialite.css'

function SectionPolitiqueConfidentialite() {
    return (
        <div className="legal-page">
            <header>
                <h1 className="legal-title">Politique de Confidentialité</h1>
            </header>
            <p className="legal-info">
                Chez FAST PROTECT, nous nous engageons à protéger vos données personnelles conformément au RGPD
                (Règlement Général sur la Protection des Données) et à la loi Informatique et Libertés du 6 janvier 1978.
            </p>

            <h2 className="legal-subtitle">Utilisation des Données</h2>
            <p className="legal-info">
                Les données collectées sont utilisées pour la gestion de vos dossiers, l'envoi de documents concernant
                nos produits d'assurance, ainsi que pour des fins de prospection commerciale. Elles peuvent être
                partagées avec nos partenaires, assureurs, et réassureurs.
            </p>

            <h2 className="legal-subtitle">COOKIES</h2>
            <p className="legal-info">
                Cette rubrique « COOKIES » vous permet de mieux comprendre l'origine et l'usage des informations de navigation traitées lors de votre consultation de notre site, ainsi que vos droits. Nous souhaitons vous offrir une expérience positive et vous rassurer quant à l'utilisation de nos services.
            </p>
            <p className="legal-info">
                Lors de votre visite sur notre site, certaines informations relatives à la navigation de votre terminal (ordinateur, tablette, smartphone, etc.) peuvent être enregistrées dans des fichiers appelés « Cookies ». Ces cookies peuvent être internes (créés par notre site) ou tiers (créés par des services externes). Sachez que seuls les émetteurs des cookies peuvent lire ou modifier les informations qu'ils contiennent. Sur notre site, nous n'utilisons que des cookies émis par FAST PROTECT.
            </p>

            <h3 className="legal-subtitle">1) Les Cookies que nous émettons</h3>
            <p className="legal-info">
                Lorsque vous accédez à notre site, nous installons des cookies dans votre terminal pour reconnaître votre navigateur pendant leur durée de validité. Ces cookies nous permettent de :
            </p>
            <ul className="legal-list">
                <li>Adapter la présentation de notre site à vos préférences d'affichage (langue, résolution, système d'exploitation, etc.).</li>
                <li>Vous donner accès à des espaces réservés et personnels, tels que votre compte, avec des identifiants que vous nous avez fournis.</li>
                <li>Assurer des mesures de sécurité, comme vous demander de vous reconnecter après un certain temps d'inactivité.</li>
            </ul>
            <p className="legal-info">
                Ces cookies sont essentiels pour le bon fonctionnement de certaines pages de notre site, ce qui signifie qu'ils ne nécessitent pas votre consentement préalable. Ils sont notamment utilisés pour l'authentification et d'autres fonctions techniques.
            </p>

            <h3 className="legal-subtitle">2) Vos choix concernant les cookies</h3>
            <p className="legal-info">
                Vous avez plusieurs options pour gérer les cookies :
            </p>
            <ul className="legal-list">
                <li><strong>Paramétrer votre navigateur</strong> : Vous pouvez configurer votre logiciel de navigation pour accepter ou refuser les cookies, soit de manière systématique, soit selon leur émetteur. Vous pouvez aussi choisir d'être averti avant qu'un cookie ne soit enregistré.</li>
                <li><strong>Droit de refus</strong> : Si vous refusez les cookies que nous émettons, certaines fonctionnalités de notre site peuvent ne plus être accessibles. Par exemple, vous pourriez rencontrer des difficultés pour accéder à des services nécessitant une identification.</li>
            </ul>

            <h4 className="legal-subtitle">Gestion des cookies selon votre navigateur</h4>
            <p className="legal-info">
                Chaque navigateur a sa propre méthode de gestion des cookies. Voici comment procéder pour quelques navigateurs courants :
            </p>
            <ul className="legal-list">
                <li><strong>Internet Explorer™</strong> : Allez dans "Outils" > "Options Internet" > "Confidentialité" et ajustez les paramètres.</li>
                <li><strong>Safari™</strong> : Accédez à "Préférences" > "Confidentialité" et choisissez de bloquer les cookies.</li>
                <li><strong>Chrome™</strong> : Cliquez sur l'icône de clé à molette > "Paramètres" > "Affichage des paramètres avancés" > "Confidentialité" et sélectionnez "Paramètres de contenu".</li>
                <li><strong>Firefox™</strong> : Cliquez sur le bouton Firefox > "Options" > "Vie Privée" et modifiez les paramètres des cookies.</li>
            </ul>

            <h2 className="legal-subtitle">Vos Droits</h2>
            <p className="legal-info">
                Vous avez le droit d'accéder, de rectifier, ou de supprimer vos données personnelles à tout moment.
                Pour exercer vos droits, veuillez nous contacter à : <a href="mailto:contact@fast-protect.fr">contact@fast-protect.fr</a>.
            </p>

            <h2 className="legal-subtitle">MENTIONS D'INFORMATION SUR LA PROTECTION DES DONNÉES PERSONNELLES</h2>
            <p className="legal-info">
                FAST PROTECT s'engage à protéger votre vie privée et à garantir la confidentialité des informations que vous nous fournissez, conformément au Règlement européen 2016/679 et à la Loi Informatique et Libertés.
            </p>
            <p className="legal-info">
                Les informations marquées d'un astérisque lors de leur collecte sont nécessaires pour accéder à votre compte. Sans ces informations, l'accès ne sera pas possible.
            </p>

            <h3 className="legal-subtitle">Finalités et traitements</h3>
            <p className="legal-info">
                Les données que vous nous communiquez sont nécessaires pour :
            </p>
            <ul className="legal-list">
                <li>L'examen et la gestion de vos contrats.</li>
                <li>La gestion des sinistres et des réclamations.</li>
                <li>La lutte contre la fraude et le blanchiment d'argent.</li>
                <li>La réalisation d'études statistiques et de l'archivage légal.</li>
            </ul>
            <p className="legal-info">
                Ces traitements sont réalisés dans le cadre de l'exécution de nos obligations contractuelles ou légales.
            </p>

            <h3 className="legal-subtitle">Contact et réclamation</h3>
            <p className="legal-info">
                Si vous avez des questions ou des préoccupations concernant le traitement de vos données personnelles, n'hésitez pas à nous contacter. Vous pouvez également déposer une réclamation auprès de la CNIL à l'adresse suivante :
            </p>
            <p className="legal-info">
                <strong>CNIL</strong><br />
                3 Place de Fontenoy – TSA 80715<br />
                75334 PARIS CEDEX 07
            </p>
            <p className="legal-info">
                FAST PROTECT et ses sous-traitants s'engagent chaque jour à respecter votre vie privée et à protéger vos données personnelles.
            </p>
        </div>
    );
}

export default SectionPolitiqueConfidentialite;