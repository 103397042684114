import React, { useState, useEffect } from 'react'
import { Fragment } from "react"
import IconBoxDiamond14 from "../assets/icons/IconBoxDiamond14"
import IconBoxHome14 from "../assets/icons/IconBoxHome14"
import LogoIcon from "../assets/logo/LogoIcon"
import MaxWidth from "../components/MaxWidth"
import NavLink from "../components/NavLink"
import Separator from "../components/Separator"
import IconBoxShop14 from "../assets/icons/IconBoxShop14"
import IconBoxService14 from "../assets/icons/IconBoxService14"
import menuButton from "../assets/MenuIcon.svg"
import crossButton from "../assets/CrossIcon.svg"
import '../styles/Header.css';
import MarketingBanner from '../components/MarketingBanner';



const links = [
  {
    title: "Acceuil",
    Icon: IconBoxHome14,
    link: "/",
    isExact: true
  },
  {
    title: "Nos assurances",
    Icon: IconBoxService14,
    link: "/assurances",
    isExact: false
  },
  {
    title: "À propos",
    Icon: IconBoxShop14,
    link: "/about",
    isExact: false
  },
  {
    title: "Blog",
    Icon: IconBoxShop14,
    link: "/blog",
    isExact: false
  },  {
    title: "Prendre RDV",
    Icon: IconBoxDiamond14,
    link: "/rdv",
    isExact: false
  },
]

function Header() {
  useEffect(() => {
    console.log("Tentative de chargement du script HubSpot");
    const script = document.createElement('script');
    script.src = "//js-eu1.hs-scripts.com/144634511.js";
    script.async = true;
    script.defer = true;
    script.id = "hs-script-loader";
    script.onload = () => console.log("Script HubSpot chargé avec succès");
    script.onerror = () => console.error("Erreur lors du chargement du script HubSpot");
    document.body.appendChild(script);

    return () => {
      console.log("Suppression du script HubSpot");
      const existingScript = document.getElementById("hs-script-loader");
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    }
  }, []);

  const [openMobileNav, setOpenMobileNav] = useState(false);

  return (
    <div className="header" style={{ padding: "8px 0", backgroundColor: "var(--fp-color-white)" }}>
      <MaxWidth>
      <MarketingBanner />
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <div style={{ display: "flex", padding: "0 16px", alignItems: "center" }}>
            <LogoIcon fill={"var(--fp-color-blue-main)"} />
          </div>
          <div className={openMobileNav ? 'header-links-holder active' : 'header-links-holder'}>
            {
              links.map(({ title, Icon, link, isExact }, i) => (
                <Fragment key={i}>
                  <NavLink
                    link={link}
                    isActive={isExact ? window.location.pathname === link : window.location.pathname.startsWith(link)}
                    title={title}
                    icon={<Icon fill={"var(--fp-comp-nav-link-icon-fill)"} stroke={"var(--fp-color-blue-main)"} />}
                  />
                  {i + 1 < links.length && <Separator length={"20px"} color={"var(--fp-color-blue-degree-95)"} />}
                </Fragment>
              ))
            }

            <div className="cross-button fp-comp-nav-link" onClick={()=> setOpenMobileNav(false)}>
              <div className="icon">
                <img src={crossButton} />
              </div>
              <div className="title fp-text-style-label-light">Close</div>
            </div>
          </div>

          <div className="menu-button fp-comp-nav-link" onClick={()=> setOpenMobileNav(true)}>
            <div className="icon">
              <img src={menuButton} />
            </div>
            <div className="title fp-text-style-label-light">Menu</div>
          </div>
        </div>
        
      </MaxWidth>
    </div>
  )
}

export default Header
