import React from 'react'
import LandingPage from './templates/LandingPage'
import SectionBlog from './sections/SectionBlog'
import Spacer from './components/Spacer'
import SectionContact from './sections/SectionContact'

function Blog() {
  return (
    <LandingPage>
      <Spacer size={"48px"}/>
      <SectionBlog/>
      <SectionContact/>
    </LandingPage>
  )
}

export default Blog
