import React from 'react';
import IconSimpleSend from '../assets/icons/IconSimpleSend';
import CarouselCard from '../components/CarouselCard';
import Grid from '../components/Grid';
import GridCell from '../components/GridCell';
import HeroCard from '../components/HeroCard';
import MaxWidth from '../components/MaxWidth';
import heroImage1 from '../assets/images/teamfp.png';
import heroImage3 from '../assets/images/ceo-usine.png'; // Correction des imports

function SectionHero() {
  return (
    <div
      style={{
        padding: "var(--fp-size-section-padding-v) 0",
        backgroundColor: "var(--fp-color-blue-degree-95)",
      }}
    >
      <MaxWidth>
        <Grid gutter={16} maxColumns={2}>
          <GridCell>
            <div style={{ height: "368px" }}>
              <HeroCard
                title={
                  <>
                    <span className="fp-text-style-header-big">Protection&nbsp;</span>
                    <span className="fp-text-style-header-big-special">Rapide</span>
                    <br />
                    <span className="fp-text-style-header-big">Avec Fast Protect</span>
                  </>
                }
                description={
                  "Chez Fast Protect, nous croyons que protéger vos droits et votre activité doit être aussi rapide et simple que de se connecter en ligne. Nous nous engageons à offrir une expérience fluide à nos clients en combinant expertise en secteur d'assurance et innovation digitale."
                }
                primaryAction={{
                  icon: (
                    <IconSimpleSend
                      fill={"var(--fp-comp-hero-card-icon-fill)"}
                      stroke={"var(--fp-color-blue-main)"}
                    />
                  ),
                  action: "Prendre un RDV",
                  onClick: () => {
                    window.location.href = "https://fast-protect.fr/rdv";
                  },
                }}
                secondaryAction={{
                  icon: (
                    <IconSimpleSend
                      fill={"var(--fp-comp-hero-card-icon-fill)"}
                      stroke={"var(--fp-color-blue-main)"}
                    />
                  ),
                  action: "Être contacté par un expert",
                  onClick: () => {
                    console.log("Secondary action clicked");
                  },
                }}
              />
            </div>
          </GridCell>
          <GridCell>
            <div style={{ height: "368px" }}>
              <CarouselCard
                items={[
                  {
                    title: "Assurance Protection juridique",
                    images: [
                      heroImage3, // Utilisation des images importées
                      heroImage1, // Utilisation des images importées
                      heroImage1, // Pas de variable non définie
                    ],
                  },
                ]}
              />
            </div>
          </GridCell>
        </Grid>
      </MaxWidth>
    </div>
  );
}

export default SectionHero;
