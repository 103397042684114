import AssuranceCard from './AssuranceCard';
import './AssurancesSection.scss';
import MaxWidth from './MaxWidth';
import SectionLabel from '../components/SectionLabel';
import Spacer from '../components/Spacer';
import IconBoxDiamondGreen from '../assets/icons/IconBoxDiamondGreen';
import FloatingLogo from '../components/FloatingLogo';
import AssuranceCardCoinImage from '../assets/AssuranceCardCoinImage.png';
import AssuranceCardPaperImage from '../assets/AssuranceCardPaperImage.png';
import AssuranceCardMoneyImage from '../assets/AssuranceCardMoneyImage.png';
import IconSimpleSend from '../assets/icons/IconSimpleSend'; // Assurez-vous que cet import est correct
import ResponsabiliteCivile from '../assets/images/responsabilitecivile.jpg';

import CyberRisques from '../assets/images/Cyberrisques.jpg'; 
import MaterielInformatique from '../assets/images/materielinformatique.jpg';
import BureauLocal from '../assets/images/bureaulocal.jpg';
import Garantiedecennale from '../assets/images/garantiedecennale.jpg';
import MutuelleDirigeant from '../assets/images/mutuelle-dirigeant.jpg';
import MutuelleDirigeant1 from '../assets/images/Mutuelle-dirigeant01.png';
import PrevoyanceDirigeant from '../assets/images/prevoyannce-dirigent.png';
import AssuranceHommeCle from '../assets/images/assurance-homme-clee.png';
import MutuelleEntreprise from '../assets/images/mutuelle-entreprise.png';
import Decenale  from '../assets/decenaleartisan1.png';

const assurancesData = [
  {
    imageSrc: ResponsabiliteCivile,
    title: "Responsabilité Civile",
    description: "Cette assurance covre les dommages que vous pourriez causer à autrui, que ce soit dans votre vie privée ou professionnelle."
  },
  {
    imageSrc: CyberRisques,
    title: "Assurance Cyber-Risques",
    description: "Cette assurance protège votre entreprise contre les piratages informatiques, les fuites de données et autres cyberattaques."
  },
  {
    imageSrc: MaterielInformatique,
    title: "Matériel informatique",
    description: "Cette assurance couvre les dommages subis par vos ordinateurs, portables, tablettes et autres appareils électroniques."
  },
  {
    imageSrc: BureauLocal,
    title: "Bureau local et professisonnel",
    description: "Cette assurance couvre les dommages subis par vos locaux professionnels, meubles et équipements."
  },
  {
    imageSrc: Decenale,
    title: "Garantie décennale",
    description: "Cette assurance couvre les vices de construction pouvant apparaître dans les dix ans suivant la livraison du bâtiment."
  },
  {
    imageSrc: MutuelleDirigeant1,
    title: "Mutuelle dirigeant",
    description: "Cette mutuelle offre une couverture complémentaire à la Sécurité sociale pour les dirigeants d'entreprise."
  },
  {
    imageSrc: PrevoyanceDirigeant,
    title: "Prévoyance dirigeant",
    description: "Cette assurance garantit le maintien du revenu du dirigeant en cas d'incapacité de travail."
  },
  {
    imageSrc: AssuranceHommeCle,
    title: "Assurance Homme clé",
    description: "Cette assurance protège l'entreprise contre les pertes financières liées à l'indisponibilité d'un salarié clé."
  },
  {
    imageSrc: MutuelleEntreprise,
    title: "Mutuelle entreprise",
    description: "Cette mutuelle offre une couverture complémentaire à la Sécurité sociale pour les salariés de l'entreprise."
  },
];

const AssurancesSection = () => {
  return (
    <div style={{ padding: "var(--fp-size-section-padding-v2) 0", position: "relative" }}>
      <MaxWidth>
        <div style={{ position: "relative", zIndex: "1" }}>
          <SectionLabel
            icon={<IconBoxDiamondGreen />}
            title={"Nos assurances"}
            isWhite={true}
          />
          <Spacer size={"16px"} />
          <h2 className='fp-text-style-header-large'>
            Nous offrons le meilleur service pour<br />répondre rapidement à vos besoins
          </h2>
          <Spacer size={"48px"} />

          <div className="assurances-cards">
            <div className="assurances-cards__grid">
              {assurancesData.map((assurance, index) => (
                <AssuranceCard key={index} imageSrc={assurance.imageSrc} title={assurance.title}>
                  {assurance.description}
                </AssuranceCard>
              ))}
            </div>
          </div>
        </div>
      </MaxWidth>

      <FloatingLogo isWhite={false} />
    </div>
  );
};

export default AssurancesSection;
